<template>
  <transition
    :duration="{ enter: 600, leave: 300 }"
    name="fade"
    mode="out-in"
    enter-active-class="animate__animated animate__fadeIn"
    leave-active-class="animate__animated animate__fadeOut"
  >
    <div
      class="w-full min-h-screen fixed top-0 bg-black flex justify-center items-center p-5 z-50"
      v-if="loading"
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 370 310" height="450px">
        <path
          fill="#E53E54"
          d="M165.171,173.832c0,4.564-3.693,8.258-8.255,8.258c-4.553,0-8.249-3.694-8.249-8.258
	c0-4.555,3.696-8.251,8.249-8.251C161.478,165.581,165.171,169.276,165.171,173.832z"
        />

        <g>
          <path
            fill="#58595B"
            d="M131.817,170.603c-0.58-0.933-1.452-2.394-2.04-3.381c-2.134-3.578-2.356-3.964-3.857-3.964
		c-1.977,0-1.982,0-5.869,12.375c-0.134-0.889-0.273-1.784-0.405-2.664c-3.123-20.599-3.123-20.599-5.462-20.599
		c-2.363,0-2.363,0-4.591,18.833c-1.164,9.847-2.665,22.778-4.094,35.117c-0.526,4.537-1.059,9.118-1.563,13.467
		c-1.014-15.198-2.308-34.945-3.514-53.444c-1.079-16.475-2.121-32.369-2.904-44.152c-0.39-5.892-0.717-10.752-0.956-14.146
		c-0.504-7.209-0.531-7.639-2.914-7.639c-2.284,0-2.284,0-5.033,16.549c-1.433,8.644-3.297,20.1-5.129,31.376
		c-1.448,8.882-2.981,18.327-4.244,25.967c-0.094-0.725-0.191-1.443-0.282-2.143c-2.686-20.21-2.686-20.21-5.008-20.21
		c-1.841,0-1.841,0-6.457,12.481c-0.735,1.991-1.581,4.277-2.361,6.376c-2.512-9.712-2.56-9.712-4.669-9.712
		c-1.304,0-1.372,0-9.027,7.963c-1.841,0.037-6.533-0.009-10.756-0.089l-0.092,5.03c12.823,0.242,12.857,0.207,13.79-0.753
		l0.376-0.385c2.014-2.099,3.466-3.587,4.507-4.636c0.389,1.471,0.822,3.138,1.171,4.484c2.218,8.582,2.218,8.582,4.299,8.582
		c1.891,0,1.891,0,7.483-15.111c0.21-0.573,0.426-1.156,0.64-1.739c0.363,2.707,0.758,5.667,1.12,8.384
		c2.687,20.203,2.687,20.203,5.008,20.203c2.257,0,2.257,0,4.75-15.037c1.292-7.792,2.991-18.24,4.712-28.846
		c1.35-8.286,2.839-17.437,4.139-25.346c0.792,11.9,1.813,27.521,2.818,42.883c1.253,19.154,2.556,39.089,3.567,54.234
		c0.51,7.577,0.94,13.953,1.255,18.438c0.16,2.249,0.29,4.025,0.384,5.235c0.168,2.143,0.325,4.17,2.681,4.17
		c2.311,0,2.311,0,3.996-14.294c0.864-7.326,2.013-17.235,3.209-27.556c1.324-11.441,2.817-24.319,4.002-34.344
		c0.061,0.394,0.121,0.779,0.179,1.157c2.289,15.118,2.289,15.118,4.54,15.118c1.957,0,1.957,0,5.608-11.612
		c0.546-1.749,1.182-3.757,1.751-5.551c2.18,3.623,2.565,3.965,3.98,3.965h13.175v-5.031H131.817z"
          />
          <path
            fill="#58595B"
            d="M316.523,172.011c-1.304-2.528-4.341-8.771-6.278-12.769c-4.179-8.606-4.179-8.606-5.802-8.606
		c-1.74,0-1.74,0-7.549,12.865c-0.454,0.996-0.924,2.045-1.399,3.085c-0.69-4.188-1.601-9.696-2.575-15.601
		c-2.098-12.719-4.664-28.314-6.77-40.728c-4.012-23.736-4.012-23.736-6.43-23.736c-2.397,0-2.397,0-3.792,10.842
		c-0.681,5.331-1.579,12.648-2.547,20.634c-1.874,15.383-4.491,36.841-6.07,47.12c-1.081-2.332-2.261-5.03-3.142-7.038
		c-4.076-9.31-4.426-10.019-6.14-10.347c0,0-0.878-0.009-1.201,0.094c-0.315,0.103-0.851,0.413-1.099,0.619
		c-2.918,2.386-5.528,24.59-13.303,101.987c-0.593,5.874-1.17,11.657-1.707,16.948c-1.234-24.49-2.525-63.04-3.329-91.708
		c-0.018-0.385-1.725-39.373-3.659-77.882c-4.012-80.068-4.397-80.104-7.405-80.399c-3.341-0.337-3.359-0.337-10.147,60.031
		c-3.637,32.376-8.935,79.516-11.747,92.486c-1.131-1.561-2.465-3.524-3.51-5.059c-5.157-7.565-6.336-9.059-8.215-9.175
		c-1.564-0.104-1.865,0.304-5.576,5.185c-2.011,2.645-6.6,8.681-8.262,9.909c-2.26,0.01-9.361-0.009-9.361-0.009l-0.014,5.031
		c0,0,8.39,0.018,9.906,0c2.224-0.037,4.416-2.26,11.733-11.882c0.52-0.69,1.068-1.408,1.551-2.036
		c1.192,1.579,2.852,4.009,4.079,5.811c5.354,7.855,6.591,8.878,8.592,9.336c0,0,1.201-0.019,1.497-0.234
		c0.591-0.439,0.709-0.753,0.709-0.753c2.635-3.533,6.173-31.8,13.617-98.05c1.51-13.463,3.053-27.161,4.333-37.769
		c2.001,30.824,4.924,91.698,6.852,135.648c0.009,0.288,0.826,29.36,1.902,58.072c2.258,60.394,2.824,60.42,5.981,60.519
		c0.188,0.009,0.368,0.019,0.532,0.019c2.112,0,2.215-0.969,3.647-14.168c0.798-7.426,1.837-17.756,3.004-29.378
		c3.035-30.191,7.814-77.79,10.761-93.947c0.46,1.038,0.926,2.114,1.371,3.11c5.492,12.545,6.448,14.158,8.528,14.419
		c0,0,0.771,0.107,1.031,0.036c0.251-0.073,0.718-0.35,0.869-0.565c0.898-1.283,1.797-1.399,8.385-55.374
		c0.582-4.803,1.174-9.671,1.712-14.003c2.31,13.451,5.572,33.283,7.874,47.209c1.099,6.682,2.07,12.573,2.769,16.798
		c0.354,2.107,0.641,3.801,0.845,4.977c0.367,2.133,0.681,4.125,3.101,3.864c1.516-0.179,1.516-0.179,6.798-11.873
		c0.949-2.098,2.072-4.582,3.022-6.662c0.391,0.807,0.808,1.676,1.22,2.519c7.586,15.611,7.586,15.611,9.354,15.611h12.84v-5.03
		H316.523z"
          />
          <rect x="0" y="0" width="370" height="310" fill="#000">
            <animate
              attributeType="XML"
              attributeName="x"
              from="0"
              to="370"
              begin="0s"
              dur="0.9s"
              repeatCount="indefinite"
            />
          </rect>
        </g>
      </svg>
    </div>
  </transition>
</template>

<script>
export default {
  name: "Loading",
  data() {
    return {
      loading: true
    };
  },
  mounted() {
    setTimeout(() => {
      this.loading = false;
      this.$nextTick();
    }, 600);
  }
};
</script>
