<template>
  <section
    class="w-full bg-cover bg-local relative bg-center"
    :style="`background-image: url('${require('@/assets/img/indi.jpg')} ')`"
  >
    <Right class="absolute bottom-0 left-0" />
  </section>
</template>

<script>
import Right from "@/components/Theme/Right";

export default {
  name: "Copy",
  components: {
    Right,
  },
};
</script>
