<template>
  <section class="w-full bg-thirdry text-gray-200 flex justify-center items-center">
    <div
      class="w-full py-10 text-gray-200 flex flex-col justify-center items-center"
      v-animate-onscroll="'animate__animated animate__fadeIn animate__slow'"
    >
      <div class="container px-5 flex md:flex-col text-center">
        <div class="flex-1 px-5 md:pb-5">
          <h2 class="lg:text-4xl text-2xl font-extrabold">Co czeka Cię w miejscu odkrycia?</h2>
          <ul class="pb-3 list-inside lg:text-xl text-lg text-left">
            <li class="list-check">Inspiracyjne życiowe przeżycie</li>
            <li class="list-check">Przyjazna, pełna dobroci atmosfera</li>
            <li class="list-check">Doświadczenie pięknego stanu</li>
            <li class="list-check">Głębsze wewnętrzne zrozumienie siebie</li>
            <li class="list-check">Radość, nowy sens życia, pokój serca</li>
          </ul>
          <h4 class="lg:text-4xl text-xl font-bold">Najbliższy termin event Good Way Life Discovery</h4>
          <div class="lg:text-xl text-lg">
            <p v-html="data.day"></p>
            <p class="pb-3" v-html="data.place"></p>
            <p>Do zobaczenia w miejscu odkrycia!</p>
            <p>Krzysztof Janowski</p>
          </div>
        </div>
        <div class="flex-1 px-5">
          <h4
            class="text-center lg:text-4xl text-2xl font-extrabold"
          >Jak zapisać się na odkrycie Good Way?</h4>
          <p
            class="mb-3 lg:text-xl text-lg text-center"
          >Wystarczy, że pozostawisz swoje dane, a na Twój e-mail otrzymasz formularz zapisu. To jest ostatni krok, by poczuć moc, która czeka na Ciebie w miejscu odkrycia.</p>
          <Form />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import Form from "@/components/Event/Form";

export default {
  name: "Place",
  data() {
    return {
      data: {}
    };
  },
  components: {
    Form
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      await axios.get("/api/app/event").then(response => {
        this.data = response.data[0];
      });
    }
  }
};
</script>
