<template>
  <section class="w-full bg-thirdry text-gray-200 flex flex-col items-center">
    <div class="bg-primary text-gray-200 flex justify-center items-center w-full">
      <img class="lg:h-64 md:h-32" src="@/assets/img/logo.svg" alt="Good Way" />
    </div>
    <div
      class="w-full py-10 text-gray-200 flex flex-col justify-center items-center animate__animated animate__fadeIn animate__slow"
    >
      <h2 class="lg:text-4xl text-2xl font-extrabold lg:pt-5">Miejsce odkrycia</h2>
      <div class="container px-10 text-center lg:text-2xl text-lg" v-html="text"></div>
      <a href="#discoversec" v-scroll-to="'#discoversec'">
        <svg
          class="h-10 fill-current bounce my-2 mx-auto"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path
            d="M13.418,7.859c0.271-0.268,0.709-0.268,0.978,0c0.27,0.268,0.272,0.701,0,0.969l-3.908,3.83
	c-0.27,0.268-0.707,0.268-0.979,0l-3.908-3.83c-0.27-0.267-0.27-0.701,0-0.969c0.271-0.268,0.709-0.268,0.978,0L10,11L13.418,7.859z
	"
          />
        </svg>
      </a>
    </div>
  </section>
</template>

<script>
import axios from "axios";

export default {
  name: "Discover",
  data() {
    return {
      text: ""
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      await axios.get("/api/app/6").then(response => {
        this.text = response.data.text;
      });
    }
  }
};
</script>
