<template>
  <div div id="index" ref="index">
    <Loading />
    <component
      v-bind:is="item.component"
      v-for="(item, index) in items"
      :key="index"
      :id="item.name"
      class="min-h-screen"
    ></component>
  </div>
</template>

<script>
import Loading from "@/components/Theme/Loading";
import Discover from "@/components/Event/Discover";
import DiscoverSec from "@/components/Event/DiscoverSec";
import DiscoverTh from "@/components/Event/DiscoverTh";
import Place from "@/components/Event/Place";
import Copy from "@/components/Event/Copy";

export default {
  name: "Event",
  data() {
    return {
      items: [
        { component: "Discover", name: "discover" },
        { component: "DiscoverSec", name: "discoversec" },
        { component: "DiscoverTh", name: "discoverth" },
        { component: "Place", name: "place" },
        { component: "Copy", name: "copy" }
      ]
    };
  },
  components: {
    Loading,
    Discover,
    DiscoverSec,
    DiscoverTh,
    Place,
    Copy
  }
};
</script>
